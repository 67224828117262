import { useState } from 'react'

import { BRAND_TOGGLES } from '../../brands/toggles'
import { ConsentCookie } from '../../helpers/cookie'
import { EMBEDDED_FIND_HOME_PATH } from '../../legacy-pages/embedded-find-home'
import { usePathname } from '../../vendor/next'

import { CookieConsentDialog } from './cookie-consent-dialog'

const isNativeApp = typeof window !== 'undefined' && window.isNativeApp

export const getShouldAskForCookieConsent = () => {
  /* If using sourcepoint client, it determines if consent is needed */
  if (BRAND_TOGGLES.usesSchibstedSourcepointClient || isNativeApp) {
    return false
  }

  return !ConsentCookie.hasMadeChoice()
}

export function CookieConsent() {
  const pathname = usePathname()
  const [isOpen, setIsOpen] = useState(true)
  const shouldAskForCookieConsent = getShouldAskForCookieConsent()
  const isDisabledInCurrentRoute = pathname.includes(EMBEDDED_FIND_HOME_PATH)
  const shouldHideCookieConsentDialog = !shouldAskForCookieConsent || isDisabledInCurrentRoute

  if (shouldHideCookieConsentDialog) {
    return null
  }

  return <CookieConsentDialog isOpen={isOpen} setIsOpen={setIsOpen} />
}
